import React from 'react';
import './DashboardHome.css';
import HomeTopWidget from './components/HomeTopWidget';
import widgetFriends from '../../../assets/images/home-widget-friends.png'
import widgetClubs from '../../../assets/images/home-widget-clubs.png'
import widgetShoots from '../../../assets/images/home-widget-shoots.png'
import widgetEquipments from '../../../assets/images/home-widget-equipments.png'
import HomeActivity from './components/HomeActivity';
import HomeSubscribe from './components/HomeSubscribe';
import ClubsCTA from './components/ClubsCTA';
import ClubEvents from './components/ClubEvents';

const DashboardHomePage = () => {
    
    return (
        <div className="dashboard-component">
            <div className='row'>
                <div className="col-lg-3 col-md-6 dashboard-card-container">
                    <HomeTopWidget img={widgetFriends} number={42} title={"Total Friends"} />
                </div>
                <div className="col-lg-3 col-md-6 dashboard-card-container">
                    <HomeTopWidget img={widgetClubs} number={13} title={"Total Clubs"} />
                </div>
                <div className="col-lg-3 col-md-6 dashboard-card-container">
                    <HomeTopWidget img={widgetShoots} number={87} title={"Total Shoots"} />
                </div>
                <div className="col-lg-3 col-md-6 dashboard-card-container">
                    <HomeTopWidget img={widgetEquipments} number={4} title={"Pieces of Equipment"} />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-8 col-md-6">
                    <HomeActivity />
                </div>
                <div className="col-lg-4 col-md-6" style={{ marginTop: 55 }}>
                    <div className="row">
                        <div className="col-12 dashboard-card-container">
                            <HomeSubscribe />
                        </div>
                        <div className="col-12 dashboard-card-container">
                            <ClubsCTA />
                        </div>
                        <div className="col-12 dashboard-card-container">
                            <ClubEvents />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHomePage;