import { CalendarDays, MapPin, MessageCircle, MoveRight, Send, ThumbsUp } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import shooter from '../../../../assets/images/activity-shooter-1.jpg'

const HomeActivity = () => {

    const activities = [
        {
            name: "Fun Shoot",
            date: "30/10/2024",
            location: "Thimbleby Shooting Ground",
            shooters: [
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
            ]
        },
        {
            name: "Fun Shoot",
            date: "30/10/2024",
            location: "Thimbleby Shooting Ground",
            shooters: [
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
            ]
        },
        {
            name: "Fun Shoot",
            date: "30/10/2024",
            location: "Thimbleby Shooting Ground",
            shooters: [
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
            ]
        },
        {
            name: "Fun Shoot",
            date: "30/10/2024",
            location: "Thimbleby Shooting Ground",
            shooters: [
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
                {
                    image: shooter,
                    name: "Joe B",
                    type: "Browning"
                },
            ]
        }
    ]

  return (
    <div className='home-activity'>
        <div className="home-activity-heading">
            <h5>Activity:</h5>
            <div className="dashboard-form-field">
                <select
                    id="activity"
                    name="activity"
                >
                    <option value="Friends">Friends</option>
                </select>
            </div>
        </div>

        <div className="row">
            {
                activities.map((item, index) => (
                    <div className="col-lg-6 dashboard-card-container" key={index}>
                        <div className="dashboard-card home-activity-card">
                            <div className="home-activity-card-heading">
                                <h6>{item.name}</h6>
                                <Link to="#">
                                    More
                                    <MoveRight className='ms-2' size={17} />
                                </Link>
                            </div>
                            <div className="home-activity-info">
                                <h6><CalendarDays className='me-1 mb-1' size={14} /> {item.date}</h6>
                                <h6><MapPin className='me-1 mb-1' size={14} /> {item.location}</h6>
                            </div>
                            <h5 className="home-activity-shooters-heading">Shooters</h5>
                            <div className="activity-shooters-list">
                                {
                                    item.shooters.map((shooter, i) => (
                                        <div className="home-activity-shooter" key={i}>
                                            <img src={shooter.image} alt={shooter.name} />
                                            <div className="activity-shooter-content">
                                                <h5>{shooter.name}</h5>
                                                <h6>{shooter.type}</h6>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="home-activity-bottom">
                                <button><ThumbsUp size={14} /> Like</button>
                                <button><MessageCircle size={14} /> Comment</button>
                                <button><Send size={14} /> Share</button>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default HomeActivity