import React from 'react'

const HomeSubscribe = () => {
  return (
    <div className='home-subscribe'>
        <h1>Subscribe to <span>VIP</span> for <span>premium</span> shoot tracking & stats</h1>
    </div>
  )
}

export default HomeSubscribe