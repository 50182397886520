import { CalendarDays, MapPin, MoveRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const ClubEvents = () => {

  const events = [
    {
      name: "Fun Shoot",
      date: "30/10/2024",
      location: "Thimbleby Shooting Ground"
    },
    {
      name: "Fun Shoot",
      date: "30/10/2024",
      location: "Thimbleby Shooting Ground"
    },
    {
      name: "Fun Shoot",
      date: "30/10/2024",
      location: "Thimbleby Shooting Ground"
    },
    {
      name: "Fun Shoot",
      date: "30/10/2024",
      location: "Thimbleby Shooting Ground"
    }
  ]

  return (
    <div className='dashboard-card'>
        <div className="home-club-events-heading">
            <h5>Club Events</h5>
            <Link to="#">
                More
                <MoveRight className='ms-2' size={17} />
            </Link>
        </div>
        <div className="club-events-list">
          {
            events.map((item, index) => (
              <div className="club-event" key={index}>
                <h6 className="club-event-title">{item.name}</h6>
                <div className="club-event-info">
                  <h6><CalendarDays className='me-1 mb-1' size={14} /> {item.date}</h6>
                  <h6><MapPin className='me-1 mb-1' size={14} /> {item.location}</h6>
                </div>
              </div>
            ))
          }
        </div>
    </div>
  )
}

export default ClubEvents