import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Sidebar from '../../components/sidebar/Sidebar';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import DashboardHeader from '../../components/dashboardHeader/DashboardHeader';
import DashboardFooter from '../../components/dashboardFooter/DashboardFooter';
import Settings from './Settings/Settings';
import Friends from './Friends/Friends';
import Download from './Download/Download';
import Clubs from './Clubs/Clubs';
import ClubView from './Clubs/ClubView';
import ScoreShoot from './Score/ScoreShoot';
import DashboardScore from './Score/DashboardScore';
import Subscription from './Subscription/Subscription';
import SubscriptionSuccess from './Subscription/SubscriptionSuccess';
import SubscriptionCancel from './Subscription/SubscriptionCancel';
import { useUser } from '../../UserContext';
import Equipment from './Equipment/Equipment';
import ProfileView from './ProfileView/ProfileView';
import DashboardHomePage from './Home/DashboardHomePage';

const Dashboard = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const { user, loading, initialized, fetchUserProfile } = useUser();

    useEffect(() => {
        if (!initialized) {
            fetchUserProfile();
        }
    }, [initialized, fetchUserProfile]);

    useEffect(() => {
        if (initialized && !user) {
            navigate('/', { replace: true });
        }
    }, [initialized, user, navigate]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    if (loading || !initialized) {
        return <div className="loading">Loading...</div>;
    }

    if (!user) {
        return null; // Navigation will handle redirect
    }

    return (
        <div className='dashboard-page'>
            <Sidebar toggleSidebar={toggleSidebar} opened={sidebarOpen} />
            <div className="dashboard-page-content">
                <DashboardHeader
                    toggleSidebar={toggleSidebar}
                    opened={sidebarOpen}
                    userData={user}
                />
                <Routes>
                    <Route path="/" element={<Navigate to="home" replace />} />
                    <Route path="home" element={<DashboardHomePage />} />
                    <Route path="clubs" element={<Clubs />} />
                    <Route path="clubs/:clubId" element={<ClubView />} />
                    <Route path="score" element={<DashboardScore />} />
                    <Route path="score/score-shoot/:scoreSheetId" element={<ScoreShoot />} />
                    <Route path="friends" element={<Friends />} />
                    <Route path="friends/:friendId" element={<ProfileView />} />
                    <Route path="download" element={<Download />} />
                    <Route path="setting" element={<Settings />} />
                    <Route path="subscription" element={<Subscription />} />
                    <Route path="subscriptionSuccess" element={<SubscriptionSuccess />} />
                    <Route path="subscriptionCancel" element={<SubscriptionCancel />} />
                    <Route path="equipment" element={<Equipment />} />
                </Routes>
                <DashboardFooter />
            </div>
        </div>
    );
};

export default Dashboard;
