import { MoveRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const ClubsCTA = () => {
  return (
    <div className='dashboard-card home-clubs-cta'>
        <h5>Clubs</h5>
        <p>Search for new shooting grounds or view your favourites</p>
        <Link to="#" className="dashboard-default-btn">
            <span>View Clubs</span>
            <MoveRight size={20} className='ms-2' />
        </Link>
    </div>
  )
}

export default ClubsCTA