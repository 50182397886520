import React from 'react'

const HomeTopWidget = ({ number, title, img }) => {
  return (
    <div className='dashboard-card home-top-widget'>
        <div className="home-top-widget-left">
            <h2>{number}</h2>
            <h6>{title}</h6>
        </div>
        <img src={img} alt={title} />
    </div>
  )
}

export default HomeTopWidget